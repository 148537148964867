<template>
  <div class="m-write">
    <!-- banner -->
    <div class="banner">
      <img class="banner-bg" src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/write-banner.png" alt="写字课">
      <div class="ban-left">
        <h5>河小象系统练字课</h5>
        <p>每天15分钟，在家提升孩子书写水平</p>
        <span class="icon">科学专业的系统课程</span>
        <span class="icon">轻松的上课模式</span>
        <span class="icon">强大的师资力量</span>
      </div>
      <img class="banner-img" src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/home1.png" alt="">
    </div>
    <!-- 预约 -->
    <div class="appointment">
      <div class="tit">
        <p>免费领取</p>
        <!--<p>价值<span>199元</span>-->写字体验课</p>
      </div>
      <div class="input" :style="namenull">
        <input type="text" v-model.trim="name" :placeholder="cname" @focus="disappear" @blur="appear" >
      </div>
      <div class="input grade" @click.stop="gradeshow=!gradeshow" :style="gradenull">
        <input v-model="grade" type="text" placeholder="请选择孩子的年级" disabled>
        <div class="imgchoose">
          <div class="downimg"></div>
        </div>
      </div>
      <div class="input" :style="cellphonenull">
        <span class="areacode"><p>+86</p></span>
        <input v-model.trim="cellphone" :placeholder="cphone" @focus="pdisappear" @blur="pappear" type="number" />
      </div>
      <button class="appointBtn" @click.stop="order">立即预约</button>
      <!-- <button class="customService">
        <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/custom-service.png">
        <span>咨询客服</span>
      </button> -->
      <!-- 下拉菜单 -->
      <transition name="select">
        <div class="selectMenu" v-show="gradeshow">
          <div class="gradeList" >
            <div @click="selectgrade(index)" v-for="(item,index) in gradeList" :key="index">{{item.label}}</div>
          </div>
        </div>
      </transition>
      <transition name="slide-fade">
        <div class="message" v-if="showMessage">{{message}}</div>
      </transition>
    </div>
    <!-- 预约提示框 -->
    <div class="mask" v-if="showOrderInfo" @touchmove.prevent @mousewheel.prevent>
      <div class="orderTip">
        <h1 class="orderTitle">{{orderTitle}}</h1>
        <p class="orderMsg">稍后我们会有老师来联系您激活课程，请保持电话畅通！</p>
        <div class="closeOrder" @click.stop="showOrderInfo=false"></div>
      </div>
    </div>
    <!-- video -->
    <!-- <div class="video">
      <div class="container">
        <div class="title-template">
          <p>两分钟了解<span>河小象写字</span></p>
        </div>
        <div class="video-content">
          <div class="video-bg" @click="videoPlay" v-if="showVideoBg">
            <img class="bg" src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/video-bg.png" alt="">
            <img class="icon" src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/play-icon.png" alt="">
          </div>
          <video ref="writeVideo" class="video-player" :class="{hide: showVideoBg}" controls="" name="media" controlslist="nodownload">
            <source src="https://v.hexiaoxiang.com/9d9333e5fe994b9fa03f77dc457e1c50/08583f9dfbc5407296c0b72588d53711-5287d2089db37e62345123a1be272f8b.mp4" type="video/mp4">
          </video>
        </div>
      </div>
    </div> -->
    <!-- 专业平台 -->
    <div class="platform">
      <div class="container">
        <div class="title-template">
          <p><span>专业的</span>书法教育平台</p>
        </div>
        <div class="platform-content">
          <div class="calligraphy">
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/calligraphy-icon1.png" alt="">
            <div class="plat-txt">
              <div class="tit">全国知名书法家</div>
              <p>龚金夫，浙江省硬笔书法家协会理事，超过20年的书法教学经验。</p>
            </div>
          </div>
          <div class="calligraphy">
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/calligraphy-icon2.png" alt="">
            <div class="plat-txt">
              <div class="tit">自主研发课程体系</div>
              <p>自主研发的科学系统课程内容，真人一对一三维立体观看教学+辅导老师。笔画入门、单字、成语到古诗组成，课程编排科学有效。</p>
            </div>
          </div>
          <div class="calligraphy">
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/calligraphy-icon3.png" alt="">
            <div class="plat-txt">
              <div class="tit">一对一闪电辅导</div>
              <p>辅导老师均为本科书法专业或书法教学3年以上，当天作业1对1即时点评辅导，问题不过夜，每时每刻老师就在身边。</p>
            </div>
          </div>
          <div class="calligraphy">
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/calligraphy-icon4.png" alt="">
            <div class="plat-txt">
              <div class="tit">无忧退款承诺</div>
              <p>河小象承诺如果您不满意开课15天内我们无理由全额退款。这不仅仅是责任感，更是对我们自己专业的自信</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 课程体系 -->
    <div class="system">
      <div class="container">
        <div class="title-template">
          <p><span>科学的</span>课程体系</p>
        </div>
        <div class="system-content">
          <div class="lower-grade">
            <h5 class="tit">中低级课程</h5>
            <div class="class-level">
              <span class="level pink">启蒙课</span>
              <span class="level orange">初级上</span>
              <span class="level orange">初级下</span>
              <span class="level green">中级上</span>
              <span class="level green">中级下</span>
            </div>
            <div class="level-content">
              <div class="content pink">
                <p>· 针对基础性笔画在独体字结构中的基本书写方法</p>
                <p>· 目标：全国硬笔书法等级考试<span>1级</span>及以上</p>      
              </div>
              <div class="content orange">
                <p>· 核心掌握对于组合结构的基本书写方法及简单规律</p>
                <p>· 目标：全国硬笔书法等级考试<span>2级</span>及以上</p>
              </div>
              <div class="content orange">
                <p>· 核心掌握对于组合结构的基本书写方法及简单规律</p>
                <p>· 目标：全国硬笔书法等级考试<span>2级</span>及以上</p>
              </div>
              <div class="content green">
                <p>· 核心讲解曲线笔画在独体字及复合结构中的书写及应用</p>
                <p>· 目标：全国硬笔书法等级考试<span>3级</span>及以上</p>
              </div>
              <div class="content green">
                <p>· 核心讲解较为复杂的独体字及复合结构字形的书写及应用</p>
                <p>· 目标：全国硬笔书法等级考试<span>3级</span>及以上</p>
              </div>
            </div>
          </div>
          <div class="higher-grade">
            <h5 class="tit">高级课程</h5>
            <div class="class-level">
              <span class="level pink">阶段一</span>
              <span class="level pink">阶段二</span>
              <span class="level orange">阶段三</span>
              <span class="level orange">阶段四</span>
              <span class="level green">阶段五</span>
              <span class="level green">阶段六</span>
              <span class="level blue">阶段七</span>
              <span class="level blue">阶段八</span>
            </div>
            <div class="level-content">
              <div class="content pink">
                <p>· 核心讲解结构方面独体字结构的简单规律</p>
                <p>· 目标：全国硬笔书法等级考试<span>3级</span>及以上</p>
              </div>
              <div class="content pink">
                <p>· 核心讲解结构中笔画之间的呼应关系及不同类型笔画的书写方法</p>
                <p>· 目标：全国硬笔书法等级考试<span>3级</span>及以上</p>
              </div>
              <div class="content orange">
                <p>· 核心讲解笔画居于竖中线或竖中线两侧时的重心和倾斜角度</p>
                <p>· 目标：全国硬笔书法等级考试<span>4级</span>及以上</p>
              </div>
              <div class="content orange">
                <p>· 核心讲解取势、多点和单字中笔画的长短对比关系</p>
                <p>· 目标：全国硬笔书法等级考试<span>4级</span>及以上</p>
              </div>
              <div class="content green">
                <p>· 核心讲解长短对比问题，掌握较难部首的基本书写方法</p>
                <p>· 目标：全国硬笔书法等级考试<span>5级</span>及以上</p>
              </div>
              <div class="content green">
                <p>· 核心讲解左斜右正、左右结构、上下结构以及笔画穿插的问题</p>
                <p>· 目标：全国硬笔书法等级考试<span>5级</span>及以上</p>
              </div>
              <div class="content blue">
                <p>· 核心讲解结构方面如左右结构的收放关系等结构特征</p>
                <p>· 目标：全国硬笔书法等级考试<span>6级</span>及以上</p>
              </div>
              <div class="content blue">
                <p>· 核心讲解结构方面如包围结构、特殊结构、复合结构等结构特征</p>
                <p>· 目标：全国硬笔书法等级考试<span>6级</span>及以上</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 上课模式 -->
    <div class="class-pattern">
      <div class="container">
        <div class="title-template">
          <p><span>科学的</span>上课模式</p>
        </div>
        <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/class.png" alt="上课模式">
      </div>
    </div>
    <!-- 师资力量 -->
    <div class="teacher">
      <div class="container">
        <div class="title-template">
          <p><span>优秀的</span>师资力量</p>
        </div>
        <div class="teacher-team">
          <div class="content">
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/hx-teacher.png" alt="">
            <div class="desc">
              <p class="tit">书法家协会会员</p>
              <p>师从知名书法家王家骥先生（中书协会员、书法专业教授）</p>
              <div class="name">海象老师</div>
            </div>
          </div>
          <div class="content">
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/hm-teacher.png" alt="">
            <div class="desc">
              <p class="tit">陕西省书法家协会会员</p>
              <p>师从陕西知名书法家刘锁祥、姚国谨先生</p>
              <div class="name">河马老师</div>
            </div>
          </div>
          <div class="content">
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/fx-teacher.png" alt="">
            <div class="desc">
              <p class="tit">山东省教育书法家协会会员</p>
              <p>中国美术学院考级中心考官、书法讲师</p>
              <div class="name">飞象老师</div>
            </div>
          </div>
          <div class="content">
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/zz-teacher.png" alt="">
            <div class="desc">
              <p class="tit">省级书法家协会会员</p>
              <p>书法本科毕业，主攻二王一脉。</p>
              <div class="name">栀子老师</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 学员作品 -->
    <div class="production">
      <div class="title-template">
        <p>最新学员<span>作品</span></p>
      </div>
      <div class="newWorksBox">
        <transition name="slide-fade">
          <div class="allCardBox" v-if="showWorks == true">
            <div class="cardItem"  v-for="(item, index) in newWorksList" :key="index">
              <img :src="item.url" class="workImg">
              <div class="studentInfoBox">
                <div class="studentL">
                  <img :src="item.img" class="studentImg">
                </div>
                <div class="studentR">
                  <p class="studentItem1"><span class="studentName">{{item.name}}</span><span class="studentGrade">{{item.grade}}</span></p>
                  <p class="studentItem2"><span class="studentClass">辅导老师：{{item.teacher}}</span><span class="studentTime">{{item.time}}</span></p>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <!-- 家长反馈 -->
    <mFeedBack :list="feedBackList" colorStyle="rgba(255, 136, 64, 1)"></mFeedBack>
    <mTool :downloadShow="showDownload"></mTool>
    <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/brush-btn.png" class="brush-btn" @click="openMask">
  </div>
</template>
<script>
import mTool from '../components/mTool'
import mFeedBack from '../components/mFeedBack'
export default {
  name: 'mWrite',
  components: {
    mFeedBack,
    mTool,
  },
  data () {
    return {
      name: null, // 姓名
      grade: null, // 年级
      message: '姓名不能为空',
      showMessage: false, // 提示语
      showOrderInfo: false, // 预约成功弹窗
      orderTitle: '预约成功',
      gradeshow: false,
      gradeList: [
        {
          value: "1",
          label: "一年级"
        },
        {
          value: "2",
          label: "二年级"
        },
        {
          value: "3",
          label: "三年级"
        },
        {
          value: "4",
          label: "四年级"
        },
        {
          value: "5",
          label: "五年级"
        },
        {
          value: "6",
          label: "六年级"
        },
        {
          value: "7",
          label: "小班"
        },
        {
          value: "8",
          label: "中班"
        },
        {
          value: "9",
          label: "大班"
        },
        {
          value: "10",
          label: "其他"
        }
      ],
      cname: "请输入您家书法家的名字",
      cphone: "请输入您的手机号",
      cellphone:'',
      gradenull:'',
      namenull:'',
      cellphonenull:'',
      newWorksList: [], // 学生作品 20
      showWorks: false, //显示最新作业
      feedBackList: [
        {
          avatar: 'https://wx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLVwjvW3QicGf0J4SE2H8Kl8wWIA1LKoyJAAyiaOd8OhWibFwPL0cAZiaQoQwb2gQubZyrzZAbw2HmWfg/132',
          grade: '三年级 北京', name: '赵子萱妈妈',
          content: [
            '我家女儿是18年1月17日开始学的河小象，第一个月就有很大的进步。开学第一天老师就发现她练字了，并把语文书拿到投影仪让全班同学看。六月份硬笔书法还考过了三级，现在开始练习钢笔字了。',
            '孩子非常感谢河小象对她的帮助，并且会一直坚持练习下去。',
          ]
        },
        {
          avatar: 'https://wx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLWLzKfe5icOUU328sgv2YhDpc6NibMaicw0ScoGMcaLEd0EIvbEjrRZTC4rvUbiajztibL0cgkMXIqFNA/132',
          grade: '大班 南通', name: '朱依萱妈妈',
          content: [
            '我们家依萱是五个月前报的河小象。快上小学了，想给孩子练练字。她虽然才读大班，但是每日上写字课的时候必做的一件事：先在自己的本子上操练。自从学了河小象，她对写字越来越感兴趣了！',
            '现在我每天都在朋友圈发她的练字打卡作品。每天都有进步，真是打心底开心！',
          ]
        },
        {
          avatar: 'https://wx.qlogo.cn/mmopen/vi_32/PiajxSqBRaEIck0iaicncuBvBVTEPRJ6jicPF8Ex8ibcUSTUrseFQx60YjLc8AZ6hAS11BQ4yupkPJQ2eARvNDOFOeg/132',
          grade: '二年级 无锡', name: '姜睿东妈妈',
          content: [
            '我儿子是上了一年级之后加入了河小象，得到了班主任和木瓜老师的鼓励之后，更加用心地练字。',
            '他每天坚持练字，逐渐养成了自己的练字习惯。练习的时候，自己觉得不行，会擦掉重写，再不满意再擦，直到写到满意为止。',
            '之前陪着他一起练，给他提些意见，后来就不用常常监督了，他自己会注意要点。',
            '孩子很感谢木瓜老师的引导，在这里谢谢老师了！',
          ]
        },
        {
          avatar: 'https://wx.qlogo.cn/mmopen/vi_32/DYAIOgq83eqIxLOFg15wThxZJZOTARjqtJH4IcjP3zWib1hAsAMSl34tNPDiaDHmWqQhwd09zMgL78XqGURau6Ww/132',
          grade: '一年级 上海', name: '蒋天潇妈妈',
          content: [
            '孩子开始练字的时候是刚上大班没多久，很多字都不认识，边认边写。认字简单写字难，哪怕是最简单的“一”字想要写好也很困难。最开始，每个字都要写一个小时。但是现在已经通过了硬笔书法3级考试，每天一个字的练习时间不超过30分钟。对笔画和字的构成也都熟练多了。',
            '真感谢河小象让我的孩子有了如此大的进步！',
          ]
        },
        {
          avatar: 'https://wx.qlogo.cn/mmopen/vi_32/DYAIOgq83eoyBgb36pKU4mkZWpdsYdA2ZWpzibkK2wjP6PtV1RVVIdBdNx2ztbvmdCYwDWRzH5CkEvWxIC9gy7w/132',
          grade: '一年级 青岛', name: '闫珑缤妈妈',
          content: [
            '河小象是非常专业的线上书法平台，有着专业的书法老师和点评团队，孩子受益匪浅。',
            '珑缤在18年4月9日开始学习河小象，当初是由于我们工作忙，无暇顾及孩子，给他报了名。没想到效果出奇的好，他写的字越来越好了。上小学后作业也很快能完成，每次都是半小时内完成。',
            '从一开始不认字、不会写字，到后来了解笔画、结构，简单的字能写出笔锋了！',
          ]
        },
        {
          avatar: 'https://wx.qlogo.cn/mmopen/vi_32/PiajxSqBRaELrf1coD424lKbicVmla4hNAvcia5l2A1vEgiaxu9euR9vkusXdetSSG8jukicR5WekvvwNRaLA5C7TYQ/132',
          grade: '二年级 厦门', name: '雷顺欣妈妈',
          content: [
            '我们家孩子在18年1月份报了名。经过几个月的练习，她最近好像顿悟了一样。以前作业考卷上的字都是乱七八糟的，现在我觉得她写得格外好看！我问她：“怎么进步如此之快？”她说：“提笔即练字。”孩子终于开窍了，感谢河小象！',
          ]
        },
      ],
      showDownload: true,
      showVideoBg: true,
    }
  },
  created() {
    this.getWorkList()
  },
  methods: {
    // 播放视频
    videoPlay () {
      this.showVideoBg = false;
      let video = this.$refs.writeVideo;
      let that = this;
      video.play()
      .then(res => {
        video.addEventListener('pause', () => {
          that.showVideoBg = true;
        })
        video.addEventListener('seeking',()=>{
         that.showVideoBg=false
       })
      }).catch(err => {
        
      })
    },
    // 获取最新作业
    getWorkList() {
      this.axios.post('/api/task/recommend', {per_page: 10})
      .then((res)=>{
        if (res.data.code == 20000) {
          let tasks = res.data.data.tasks;
          this.newWorksList = tasks.reduce((pre,val) => {
            let obj = {};
            obj.url = val.photoUrl;
            obj.grade = val.info;
            var name = val.nickName;
            if (name.length >= 3) {
              name = name.slice(0, 1) + '*' + name.slice(2, 3);
            } else {
              name = name.slice(0, 1) + '*';
            }
            obj.name = name;
            obj.img = val.avatarUrl;
            obj.teacher = val.teacher;
            obj.time = this.setFormateTime(val.timestamp);
            pre.push(obj);
            return pre;
          }, [])
          // this.patchList = this.newWorksList.slice(0, 3);
          this.showWorks = true;
          // this.setNewWork();
        } else {
        }
      }).catch((error)=>{
        console.log(error);
      })
    },
    //设置时间格式
    setFormateTime(creat) {
      let date = new Date().getTime();
      var time = (date - creat) / 1000 / 3600 / 24/365;
      if (time >= 1) {
        const time1 = Math.floor(time);
        return time1 + '年前'
      }
      time = time * 365 / 30;
      if (time >= 1) {
        const time1 = Math.floor(time);
        return time1 + '月前'
      }
      time = time * 30;
      if (time >= 1) {
        const time1 = Math.floor(time);
        return time1 + '天前'
      }
      time = time * 24;
      if (time >= 1) {
        const time1 = Math.floor(time);
        return time1 + '小时前'
      }
      time = time * 60;
      if (time >= 1) {
        const time1 = Math.floor(time);
        return time1 + '分钟前'
      }
      return '刚刚'
    },
    //头部下拉选择年级
    selectgrade (index) {
      this.gradeshow = false;
      this.grade = this.gradeList[index].label;
    },
    //设置message
    setMessage() {
      this.showMessage = true;
      setTimeout(() => {
        this.showMessage = false;
      }, 3000);
    },
    //聚焦时清空名字
    disappear() {
      this.cname = " ";
    },
    //失去焦点时恢复默认输入为“请输入您家书法家的名字”
    appear() {
      this.cname = "请输入您家书法家的名字";
    },
    //同上，手机
    pdisappear() {
      this.cphone = " ";
    },
    //同上，手机
    pappear() {
      this.cphone = "请输入您的手机号";
    },
    // 预约
    order() {
      let nick_name = this.name;
      let array = [
        "",
        "一年级",
        "二年级",
        "三年级",
        "四年级",
        "五年级",
        "六年级",
        "小班",
        "中班",
        "大班",
        "其他"
      ];
      this.gradenum = array.indexOf(this.grade);
      let grade = this.gradenum;
      let cellphone = this.cellphone;
      // let source = this.getQueryString("source") || "公司"; //来源
      if (nick_name === null || nick_name === "") {
        this.message = "姓名不能为空";
        this.setMessage();
        this.nullname();
        return;
      }
      if (grade == -1 || grade === "") {
        this.message = "年级不能为空";
        this.setMessage();
        this.nullgrade();
        return;
      }
      if (cellphone == null || cellphone === "") {
        this.message = "手机号不能为空";
        this.setMessage();
        this.nullcellphone();
        return;
      }
      if (!this.isPoneAvailable(cellphone)) {
        this.message = "请输入正确的手机号";
        this.setMessage();
        this.nullcellphone();
        return;
      }
      grade = grade - 0;
      let source=this.$route.query.source||'公司'
      let params = { nick_name, grade, cellphone, source };
      let that = this;
      this.axios
        .post("/api/user/add/info", params)
        .then(res => {
          if (res.data.code == 20000) {
            that.orderTitle = "预约成功";
            that.orderMsg =
              "稍后我们会有老师来联系您激活课程，请保持电话畅通！";
            that.showOrderInfo = true;
          } else {
            if (res.data.code == 20001) {
              that.message = "此手机号已预约";
              that.setMessage();
              return;
            }
            if (res.data.code == 50000) {
              that.orderTitle = "预约失败";
              that.orderMsg = "服务端错误";
              that.showOrderInfo = true;
              return;
            }
            that.message = res.data.errMsg;
            that.setMessage();
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    getQueryString(name) {
      let reg = `(^|&)${name}=([^&]*)(&|$)`;
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    // 判断是否为手机号
    isPoneAvailable: function(pone) {
      var myreg =  /^1[0-9]{10}$/;///^[1][3,4,5,7,8][0-9]{9}$/;
      if (!myreg.test(pone)) {
        return false;
      } else {
        return true;
      }
    },
    //名字为空时变红
    nullname() {
      this.namenull = "border:1px red solid";
      setTimeout(() => {
        this.namenull = "";
      }, 1500);
    },
     //同上年级
    nullgrade() {
      this.gradenull = "border:1px red solid";
      setTimeout(() => {
        this.gradenull = "";
      }, 1500);
    },
    //同上电话
    nullcellphone() {
      this.cellphonenull = "border:1px red solid";
      setTimeout(() => {
        this.cellphonenull = "";
      }, 1500);
    },
    openMask(){
       window.location.href = "https://xcx.hexiaoxiang.com/view/signin?referId=44184&brush=1";
    }
  }
}
</script>
<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all .8s ease;
}
.slide-fade-leave-active {
  transition: all 1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to{
  opacity: 0;
}
.m-write{
  .container{
    width: 9.04rem;
    margin: 0 auto;
  }
  .title-template{
    text-align: center;
    padding-bottom: .613rem;
    >p{
      font-size:.587rem;
      font-family:MicrosoftYaHei-Bold;
      font-weight:bold;
      color:rgba(68,65,68,1);
      line-height:.853rem;
      letter-spacing: .013rem;
      span{
        color: rgba(255, 136, 64, 1);
      }
    }
  }
  .banner{
    width: 100%;
    position: relative;
    .banner-bg{
      width: 100%;
      height: 7.893rem;
      display: block;
      margin: 0 auto;
    }
    .ban-left{
      top: 0;
      left: 0;
      padding-top: .853rem;
      padding-left: .493rem;
      position: absolute;
      text-align: left;
      letter-spacing: .013rem;
      h5{
        font-size:.7rem;
        font-family:MicrosoftYaHei-Bold;
        font-weight:bold;
        color:rgba(255,255,255,1);
        line-height:.8rem;
        margin-bottom: .133rem;
      }
      p{
        font-size:.373rem;
        font-family: PingFang-SC-Regular;
        font-weight:300;
        color:rgba(255,255,255,1);
        line-height:.8rem;
        margin-bottom: .867rem;
      }
      span.icon{
        display: block;
        font-size:.32rem;
        font-weight:300;
        font-family:PingFang-SC-Regular;
        color:rgba(255,255,255,1);
        line-height: .8rem;
        background-image: url("https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/icon-mobile.png");
        background-size: .3rem auto;
        background-repeat: no-repeat;
        background-position: left center;
        padding-left: .4rem;
      }
 
    }
    .banner-img{
      position: absolute;
      top: 3rem;
      right: .4rem;
      display: block;
      width: 5.2rem;
    }
  }
  .appointment{
    width:9.04rem;
    background:rgba(255,255,255,1);
    box-shadow:0rem .427rem .667rem 0rem rgba(161,161,172,0.18);
    border-radius:.213rem;
    box-sizing: border-box;
    margin: 0 auto;
    padding: .533rem .453rem .8rem;
    display: flex;
    flex-direction: column;
    position: relative;
    top: -0.8rem;
    .tit{
      text-align: center;
      margin-bottom: .547rem;
      font-size: .587rem;
      font-family:MicrosoftYaHei-Bold;
      font-weight:bold;
      color:rgba(255,136,64,1);
      line-height:.7rem;
      letter-spacing: .013rem;
      span{
        color: rgba(241, 73, 22, 1);
      }
    }
    .input {
      height: 1rem;
      background:rgba(255,255,255,1);
      border:1px solid rgba(191,191,191,1);
      border-radius:.453rem;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
      margin-bottom: .373rem;         
      input{
        font-family:MicrosoftYaHei;
        font-weight:300;
        text-indent: 2em;
        width: 100%;
        font-size:.347rem;
        height: 100%;
        letter-spacing: .013rem;
        &::-webkit-input-placeholder{
          color:rgb(199, 196, 196);
        }
      }
      .imgchoose {
        width: .32rem;
        height: .32rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .downimg {
          width: 100%;
          height:.32rem;
          cursor: pointer;
          background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAMAAACahl6sAAAAgVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABtFS1lAAAAKnRSTlMAYvoM9fAV3wi+HUBbRzDKxToRBDXr6NTPJyLk2IuaK6dStKBsk350Za7Eky/uAAADD0lEQVR42u3c2VLiUBSF4XUEAiGIgDiAMsmgnvd/wA5d0mafhIppGfZOrf+aKvJxwbgOYIwxxhhjjDHGGGOMMcYYY4wxxhhjjDHGGGOMMcaYwkZJEsFGnWHSx7FmPm0NC/Va3vtB97gjLYb+nP9bu4GCEv9V3IHynP9qjoK2/tCgAdU5f6iJgmJvROL8dxHyLf13d4olWUer7Ab+eQSlictco6jYZ3pSKhGOVoSiRs9CovKlUThublFc485nelUokY4E+JGk3YWyShxZyUCzpMQh6sR6JVUcoeRlCjUJR1M6yiWPEyhJOoYo7/7NZ3pQIsk5qksWUFCho1zyKV4++7h6FRxSshSSMSqmxZEmJM0rS9xvLmb9/w+CKgcwr/C0LVLmEBLxRrMkdQ5gq0HiTvG0M7u+RDr6wCkkK5Sm1AHshKSHktQ6gHefrUSi2AFsfi5R7QglHziacgfwcR2JdCxwgnpCskFhBhyh5B0FmXCkkhufaYdcRhzASkhmOHMu/IhqVOLO+aXBrZBskcmUI5TM8S9jjlCyxiFrDiBpXkDiLvH14FBIlvcQncExhehcks9UYtMBjFs+09u9VQfQDyRWHaEk7lh1AIuHM0ncpX+YmQjJoHMWRxcXaPIoJA2rDmD64sXcw6oD6ArJc8OqI5W0Tzpccdf7KVlKniKrDiASktfIqgOIXsX9R1YdQPQkr8CqAxgFEquOcOD1Mv21I8KVkrOox6lVR04yseoIB14PC6uOcEzU6ldx6BoehhKrjpxkbNURzqKaQ6uOnCSx6ggHXjeJVUcqWVYaSTjFk+8qEqd6hL8WkpVZBzCXErOOUNIz6wC2wdzD7nGbmZSUOvQegJKSD7MOYCckG7OOcOD1btYBbKREOIwcECyU7Mw6woHXzKwjnEVtzTpCydysIxx4zc06gJXPFntv5Wh2ycAr61B/7D83JqqHYy+ph2MvqYcjM/Cy87cYR2dR9XDsJfVwAONmPRyppFUPB9Bv+31LmK+zi+PPWzDGGGOMMcYYY4wxxhhjjDHGGGOMMWalP5yJd0BB61pGAAAAAElFTkSuQmCC");
          background-size: cover;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .areacode {
        width: 1.6rem;
        height: .64rem;
        color: rgba(186, 186, 186, 1);
        border-right: .013rem solid rgba(186, 186, 186, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .347rem;
      }
    }
    .grade input{
      width: 90%;
    }
    .appointBtn{
      width:100%;
      height:1.1rem;
      background:linear-gradient(270deg,rgba(248,120,51,1) 0%,rgba(255,139,53,1) 100%);
      box-shadow:0rem .133rem .24rem 0rem rgba(252,114,46,0.26);
      border-radius:.507rem;
      font-size:.453rem;
      font-family:PingFang-SC-Medium;
      font-weight:500;
      color:rgba(255,255,255,1);
      letter-spacing: .027rem;
    }
    .customService{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: .453rem;
        span{
          margin-left: .2rem;
          width:1.293rem;
          font-size:.32rem;
          font-family:MicrosoftYaHeiLight;
          font-weight:300;
          color:rgba(136,136,136,1);
        }
        img{
          width: .347rem;
          height: .347rem;
        }
    }
    .selectMenu{
      position: absolute;
      width: 7.5rem;
      top: 4.85rem;
      left: 50%;
      transform: translateX(-50%);
      border: .013rem solid rgba(0, 0, 0, .05);
      background-color: #fff;
      .gradeList {
        width: 100%;
        font-size: .3rem;
        display: flex;
        flex-wrap: wrap;
        &:not(:last-child){
          border-right: 1px solid rgba(0, 0, 0, .1);
        }
        & div {
          width: 50%;
          box-sizing: border-box;
          padding: 0 0.3rem;
          line-height: .85rem;
          cursor: pointer;
          &:nth-child(4n) {
            background: rgba(0, 0, 0, .03);
          }
          &:nth-child(4n+1) {
            background: rgba(0, 0, 0, .03);
          }
        }
      }
    }
    .message {
      position: absolute;
      top: 24%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      padding: 0 .227rem;
      box-sizing: border-box;
      width: 2.933rem;
      height: .8rem;
      line-height: .8rem;
      font-size: .28rem;
      color: #fefefe;
      background-color: rgba(0, 0, 0, 0.6);
      border-radius: .5rem;
      z-index: 600;
      letter-spacing: 1px;
      overflow: hidden;
      text-align: center;
    }
  }
  .mask{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 99;
    .orderTip {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 1rem 0.55rem;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: .213rem;
      width: 7.2rem;
      z-index: 1000;
      border: #e3ebf6 .013rem solid;
      .orderTitle {
        margin-bottom: .5rem;
        height: .6rem;
        line-height: .6rem;
        font-size: .6rem;
        color: #71c5ff;
      }
      .orderMsg {
        line-height: .65rem;
        color: #515558;
        font-size: .4rem;
        letter-spacing: 1px;
        text-align: center;
      }
      .closeOrder {
        position: absolute;
        right: .267rem;
        top: .267rem;
        width: .62rem;
        height: .62rem;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAAhFBMVEUAAADJycm/v7++vr6+vr7Hx8e/v7/BwcG/v7/Dw8O+vr6+vr6/v7+/v7+/v7+/v7+/v7+/v7+/v7/AwMC/v7++vr6/v7/AwMC/v7+/v7+/v7/CwsK/v7+/v7+/v7/AwMDBwcG/v7+/v7+/v7+/v7++vr7AwMC+vr6+vr6+vr6+vr6/v7+9+e0GAAAAK3RSTlMABvgY3w2wE7wKxfHs1MuAuLSkIuSQdx7YnIgn56tcPzVxZGHPyUeYU0zNpVd7SgAAAblJREFUOMu9lNl2ozAQRI2Q2SQZDJjdeIszydT//9/ESmNJBDLn5CF6aSTu6Sq6UW9+YYX9+Rz/D/LeAobHGpL0/E2ugwDAo0PXBgqQXbyCMbDsGtK2qBSG2wLXl2Ct7xyNJaLtnHsXSPovljs0tXt0AnJvQWdksrD3hUDlACYD45afuEG3VooLlPn4FMF60Y5GzGcs1DJ56NQrf9VBiClliwOpNJYfv8FFP1RIqQiMUaYcxrnPkdN7KT8fXo3DoyaJO06nf3CiOD71AiKJo0xkTTHTJy/TpM9JhbwpHUWpg0XWHJndJy70K9yd/kbgJSKnn3c8tsWs2l4C4iznxQIYf4BJPANrLb2bcVk2I3fwvnzMNvnQ9SKXHKQOClvD7bS/B2kdkuYBo8XttYq3x+5J3qjZJwQT96K5ObkHXV3J/IkLNOeSIZPP/7a1/19DpmStmhQFen1zbm7lr/VnwvIpU+Gv981VGE2RFfI1rnP8+BzpMldBOU0q5OIA8HLI2aiom4WR0idmpJg+RfMh5bdsPqSoU4M19sJrxvDytmw87iQAwXd3LgC2f9+sr3MaqZKxQUWXcPOz9Q93ASPBvzinwwAAAABJRU5ErkJggg==");
        background-size: .62rem .62rem;
        cursor: pointer;
      }
    }
  }
  .video{
    margin-bottom: .533rem;
    .video-content{
      width:100%;
      height:5.093rem;
      background:rgba(49,49,49,1);
      .video-bg{
        position: relative;
        width:100%;
        height:100%;
        img{
          display: block;
        }
        .bg{
          width:100%;
          height:100%;
        }
        .icon{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 1.1rem;
          height: 1.1rem;
        }
      }
      .video-player{
        width: 100%;
      }
      .hide{
        display: none;
      }
    }
  }
  .platform{
    background:rgba(246,246,249,1);
    padding-top: .56rem;
    padding-bottom: .533rem;
    .calligraphy{
      width:100%;
      box-sizing: border-box;
      padding: .427rem .453rem .467rem;
      background:rgba(255,255,255,1);
      box-shadow:0rem .427rem .667rem 0rem rgba(161,161,172,0.18);
      border-radius:.213rem;
      display: flex;
      align-items: center;
      position: relative;
      &:not(:last-child){
        margin-bottom: .4rem;
      }
      img{
        display: block;
        margin-right: .533rem;
      }
      &::after{
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: .053rem;
        height: 95%;
        background: rgba(255, 136, 64, 1);
        border-radius: .213rem 0 0 .213rem;
      }
      &:nth-child(1){
        img{
          width: 1.467rem;
          height: 1.493rem;
        }
      }
      &:nth-child(2){
        img{
          width: 1.333rem;
          height: 1.467rem;
        }
        &::after{
          background: rgba(84, 97, 255, 1);
        }
      }
      &:nth-child(3){
        img{
          width: 1.453rem;
          height: 1.453rem;
        }
        &::after{
          background: rgba(251, 52, 73, 1);
        }
      }
      &:nth-child(4){
        img{
          width: 1.427rem;
          height: 1.453rem;
        }
        &::after{
          background: rgba(252, 204, 58, 1);
        }
      }
      .plat-txt{
        flex: 1;
        text-align: left;
        font-size:.32rem;
        font-family:PingFang-SC-Regular;
        font-weight:400;
        color:rgba(112,108,112,1);
        line-height:.427rem;
        letter-spacing: .027rem;
        .tit{
          width:100%;
          height:.387rem;
          font-size:.4rem;
          font-family:PingFang-SC-Bold;
          font-weight:bold;
          color:rgba(68,65,68,1);
          line-height:.6rem;
          margin-bottom: .3rem;
          letter-spacing: .013rem;
        }
      }
    }
  }
  .system{
    padding-top: .56rem;
    padding-bottom: 1.067rem;
    .system-content{
      .tit{
        width: 100%;
        padding-bottom: .333rem;
        font-size:.453rem;
        font-family:PingFang-SC-Bold;
        font-weight:bold;
        color:rgba(68,61,55,1);
        line-height:.48rem;
        text-align: left;
      }
      .lower-grade{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .lower{
          height:6.533rem;
        }
      }
      .higher-grade{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .higher{
          height:10.533rem;
        }
        .tit{
          padding-top: .613rem;
        }
      }
      .class-level{
        color: #fff;
        width:2.133rem;
        margin-right: .107rem;
        .level{
          width:100%;
          font-size:.427rem;
          font-family:PingFang-SC-Bold;
          font-weight:bold;
          color:rgba(255,255,255,1);
          line-height:1.76rem;
          display: block;
          height:1.76rem;
          margin-bottom: .107rem;
          position: relative;
          &:nth-child(1){
            border-radius:.213rem 0rem 0rem 0rem;
          }
          &:last-child{
            border-radius:0rem 0rem 0rem .213rem;
          }
          &:not(:last-child):after{
            content: '';
            bottom: -0.61rem;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            border-top: .3rem solid #fff;
            border-left: .3rem solid transparent;
            border-right: .3rem solid transparent;
            border-bottom: .3rem solid transparent;
            z-index: 2;
          }
          &:not(:last-child):before{
            content: '';
            bottom: -0.44rem;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            border-left: .23rem solid transparent;
            border-right: .23rem solid transparent;
            border-bottom: .23rem solid transparent;
            z-index: 3;
          }
        }
        .pink::before{
          border-top: .23rem solid rgba(255,135,122,1);
        }
        .orange::before{
          border-top: .23rem solid rgba(254, 157, 112, 1);
        }
        .green::before{
          border-top: .23rem solid rgba(109, 212, 219, 1);
        }
        .blue::before{
          border-top: .23rem solid rgba(89, 195, 243, 1);
        }
      }
      .level-content{
        flex: 1;
        color: #fff;
        .content{
          height:1.76rem;
          padding: .227rem .267rem;
          box-sizing: border-box;
          margin-bottom: .107rem;
          text-align: left;
          display: flex;
          align-content: center;
          flex-wrap:wrap;
          font-size:.31rem;
          font-family:PingFang-SC-Regular;
          font-weight:400;
          color:rgba(255,255,255,1);
          line-height:.45rem;
          letter-spacing: .013rem;
          &:nth-child(1){
            border-radius:0 .213rem 0rem 0rem;
          }
          &:last-child{
            border-radius:0rem 0rem .213rem 0rem;
          }
          p{
            width: 100%;
          }
        }
      }
    }
    .pink{
      background:rgba(255,135,122,1);
    }
    .orange{
      background:rgba(254, 157, 112, 1);
    }
    .green{
      background:rgba(109, 212, 219, 1);
    }
    .blue{
      background:rgba(89, 195, 243, 1);
    }
  }
  .class-pattern{
    img{
      display: block;
      width: 9.04rem;
      height: 8.907rem;
    }
    padding-bottom: .8rem;
  }
  .teacher{
    background:rgba(246,246,249,1);
    padding-top: .56rem;
    .teacher-team{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .content{
        width: 4.187rem;
        margin-bottom: .533rem;
        img{
          display: block;
          width: 4.187rem;
          height: 3.6rem;
        }
        .desc{
          background:rgba(255,255,255,1);
          box-shadow:0rem .427rem .667rem 0rem rgba(161,161,172,0.18);
          border-radius:0rem 0rem .213rem .213rem;
          width:100%;
          min-height: 1.947rem;
          background:rgba(255,255,255,1);
          box-sizing: border-box;
          padding: 0 .2rem .32rem;
          position: relative;
          .name{
            width:2.533rem;
            height:.667rem;
            background:rgba(255,136,64,1);
            border-radius:.333rem;
            top: -0.333rem;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            font-size:.3rem;
            letter-spacing: 1px;
            font-family:MicrosoftYaHei-Bold;
            font-weight:bold;
            color:rgba(255,255,255,1);
            line-height:.667rem;
            text-align: center;
          }
          p{
            text-align: left;
            font-size:.36rem;
            font-family:PingFang-SC-Regular;
            font-weight:400;
            color:rgba(136,136,136,1);
            line-height:.5rem;
          }
          p.tit{
            text-align: center;
            color:rgba(68,61,55,1);
            line-height:.5rem;
            padding-top: .427rem;
            padding-bottom: .293rem;
          }
        }
      }
    }
  }
  .production {
    padding-top: 1.2rem;
    box-sizing: border-box;
    width: 100vw;
    height: 12.91rem;
    .headline4 {
      margin-bottom: 0.8rem;
      width: 100%;
      height: 0.67rem;
      line-height: 0.67rem;
      text-align: center;
      font-size: 0.67rem;
      color: #515558;
    }
    .headline4Box, .headline4Item {
      width: 100%;
      height: auto;
    }
    .newWorksBox {
      padding: 0.08rem 0;
      width: 100vw;
      height: 9.5rem;
      overflow: hidden;
      overflow-x: auto;
      .allCardBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 78rem;
        height: 9.3rem;
        padding: 0 1.35rem;
        .cardItem {
          position: relative;
          flex: 0 0 6.92rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 6.92rem;
          height: 9.3rem;
          box-shadow:0px 2px 10px 0px rgba(0,0,0,0.26);
          border-radius: 8px;
          overflow: hidden;
          .workImg {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 6.92rem;
            height: 9.3rem;
            border-radius: 8px;
            overflow: hidden;
          }
          .studentInfoBox {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 0;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 6.92rem;
            height: 1.6rem;
            background-color: rgba(0,0,0,0.5);
            color: #fff;
            .studentL {
              flex: 0 0 1.36rem;
              width: 1.36rem;
              height: 0.85rem;
              .studentImg {
                width: 0.85rem;
                height: 0.85rem;
                border-radius: 0.4rem;
                background-color: #eee;
                display: block;
                margin-left: .3rem;
              }
            }
            .studentR {
              flex: 1;
              padding: 0 0.3rem 0 0;
              .studentItem1 {
                margin-bottom: 0.2rem;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 0.38rem;
                line-height: 0.37rem;
                .studentName {
                  margin-right: 0.2rem;
                  font-size: 0.37rem;
                }
                .studentGrade {
                  font-size: 0.32rem;
                }
              }
              .studentItem2 {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 0.32rem;
                line-height: 0.32rem;
                font-size: 0.32rem;
              }
            }
          }
        }
      }
    }
    .newWorksBox::-webkit-scrollbar {
      display: none;
    }
  }
  .brush-btn{
   position: fixed;
   top: 8rem;
    right: 0;
    width: 2.32rem;
    height: 2.427rem;
  }
  .mask{
    position: fixed;
    top:0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background:rgba(0,0,0,0.6); 
  }
  .maskBox{
    width: 8.04rem;
    height: 6.773rem;
    border-radius: .427rem;
    background: #fff;
    margin: 4rem auto;
    position: relative;
  }
  .mask-close{
    position: absolute;
    width: .64rem;
    height: .64rem;
    right: .267rem;
    top: .267rem;
  }
  .maskBox h3{
    color: #FA6400;
    font-size: .48rem;
    padding: .64rem 0 .267rem;
  }
  .maskBox p{
    font-size: .427rem;
    color: #2D2E3F;
  }
  .mask-img{
    width: 3.2rem;
    height: 3.2rem;
    margin-top: .453rem;
  }
}
</style>
